﻿

/*Colors*/

$color_p1: #ffa4bc; //Dark Pink h-navbar
$color_p2: #303030; //Dark Gray
$color_p3: #5296a4; //Dark Blue
$color_p4: #ae576b; //redish brown


$color_a1: #f4e5e9; //light pink
$color_a2: #f5f5f5; //light Gray
$color_a3: #61aabc; // light Blue

$color_e1: #f00; //red
$color_e2: #cf1c4c;
$color_e3: #58b8a5; //light green
$color_e4: #e6e6e6;

$color_red_700: #E53935;

$color_gray_50: #FAFAFA;

$color_bluegray_50: #ECEFF1;

//https://maketintsandshades.com/#5296a4
$color_p3_s60: #bad5db; // shade 60%;
$color_p3_s90: #eef5f6; // shade 90%;

$color_p3_t10: #4a8794; // tint 10%;
$color_p3_t30: #396973; // tint 30%;

$color_e3_t20: #469384;



$color_p4_s30: #c68997; // shade 30%;
$color_p4_s60: #dfbcc4; // shade 60%;
$color_p4_s90: #f7eef0; // shade 90%;

$color_p4_t10: #9d4e60; // tint 10%;

$color_p2_s10: #454545;
$color_p2_s20: #595959;
$color_p2_s30: #6e6e6e;
$color_p2_s70: #c1c1c1;

$color_c_s60: #fef8a8;
$color_c_s90: #fffde9;

$color_e1_s90: #fcebeb;

$color_e3_s80: #def1ed;
$color_e3_s90: #eef8f6;

$color_e3_t20: #469384;
$color_e3_t30: #3e8173;