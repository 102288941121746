/*Colors*/
/*Colors*/
.hs-color-p1 {
  color: #ffa4bc !important;
}

.hs-color-p2 {
  color: #303030 !important;
}

.hs-color-p3 {
  color: #5296a4 !important;
}

.hs-color-p4 {
  color: #ae576b !important;
}

.hs-color-a1 {
  color: #f4e5e9 !important;
}

.hs-color-a2 {
  color: #f5f5f5 !important;
}

.hs-color-a3 {
  color: #61aabc !important;
}

.hs-color-e1 {
  color: #f00 !important;
}

.hs-color-e2 {
  color: #cf1c4c !important;
}

.hs-color-e3 {
  color: #58b8a5 !important;
}

.hs-color-e4 {
  color: #e6e6e6 !important;
}

.hs-color-p3-s60 {
  color: #bad5db;
}

.hs-color-p3-s90 {
  color: #eef5f6;
}

.hs-color-p3-t10 {
  color: #4a8794 !important;
}

.hs-color-p3-t30 {
  color: #396973 !important;
}

.hs-color-e3-t20 {
  color: #469384 !important;
}

.hs-color-p4-s30 {
  color: #c68997 !important;
}

.hs-color-p4-t10 {
  color: #9d4e60 !important;
}

/*Backgrounds*/
.hs-bg-p1 {
  background-color: #ffa4bc !important;
}

.hs-bg-p2 {
  background-color: #303030 !important;
}

.hs-bg-p3 {
  background-color: #5296a4 !important;
}

.hs-bg-p4 {
  background-color: #ae576b !important;
}

.hs-bg-a1 {
  background-color: #f4e5e9 !important;
}

.hs-bg-a2 {
  background-color: #f5f5f5 !important;
}

.hs-bg-a3 {
  background-color: #61aabc !important;
}

.hs-bg-e1 {
  background-color: #f00 !important;
}

.hs-bg-e2 {
  background-color: #cf1c4c !important;
}

.hs-bg-e3 {
  background-color: #58b8a5 !important;
}

.hs-bg-e4 {
  background-color: #e6e6e6 !important;
}

.hs-bg-p3-s60 {
  background-color: #bad5db !important;
}

.hs-bg-p3-s90 {
  background-color: #eef5f6 !important;
}

.hs-bg-p3-t10 {
  background-color: #4a8794 !important;
}

.hs-bg-p3-t30 {
  background-color: #396973 !important;
}

.hs-bg-e3-t20 {
  background-color: #469384 !important;
}

.hs-bg-p4-s30 {
  background-color: #c68997 !important;
}

.hs-bg-p4-t10 {
  background-color: #9d4e60 !important;
}

.hs-bg-e1-s90 {
  background-color: #fcebeb !important;
}

.hs-bg-red-700 {
  background-color: #E53935 !important;
}

/*Border Color*/
.hs-bc-p1 {
  border-color: #ffa4bc !important;
}

.hs-bc-p2 {
  border-color: #303030 !important;
}

.hs-bc-p3 {
  border-color: #5296a4 !important;
}

.hs-bc-p4 {
  border-color: #ae576b !important;
}

.hs-bc-a1 {
  border-color: #f4e5e9 !important;
}

.hs-bc-a2 {
  border-color: #f5f5f5 !important;
}

.hs-bc-a3 {
  border-color: #61aabc !important;
}

.hs-bc-e1 {
  border-color: #f00 !important;
}

.hs-bc-e2 {
  border-color: #cf1c4c !important;
}

.hs-bc-e3 {
  border-color: #58b8a5 !important;
}

.hs-bc-e4 {
  border-color: #e6e6e6 !important;
}

.hs-bc-p3-s60 {
  border-color: #bad5db;
}

.hs-bc-p3-s90 {
  border-color: #eef5f6;
}

.hs-bc-p3-t10 {
  border-color: #4a8794 !important;
}

.hs-bc-p3-t30 {
  border-color: #396973 !important;
}

.hs-bc-e3-t20 {
  border-color: #469384 !important;
}

.hs-bc-p4-s30 {
  border-color: #c68997 !important;
}

.hs-bc-p4-t10 {
  border-color: #9d4e60 !important;
}

/*Colors*/
.hs-color-p1 {
  color: #ffa4bc !important;
}

.hs-color-p2 {
  color: #303030 !important;
}

.hs-color-p3 {
  color: #5296a4 !important;
}

.hs-color-p4 {
  color: #ae576b !important;
}

.hs-color-a1 {
  color: #f4e5e9 !important;
}

.hs-color-a2 {
  color: #f5f5f5 !important;
}

.hs-color-a3 {
  color: #61aabc !important;
}

.hs-color-e1 {
  color: #f00 !important;
}

.hs-color-e2 {
  color: #cf1c4c !important;
}

.hs-color-e3 {
  color: #58b8a5 !important;
}

.hs-color-e4 {
  color: #e6e6e6 !important;
}

.hs-color-p3-s60 {
  color: #bad5db;
}

.hs-color-p3-s90 {
  color: #eef5f6;
}

.hs-color-p3-t10 {
  color: #4a8794 !important;
}

.hs-color-p3-t30 {
  color: #396973 !important;
}

.hs-color-e3-t20 {
  color: #469384 !important;
}

.hs-color-p4-s30 {
  color: #c68997 !important;
}

.hs-color-p4-t10 {
  color: #9d4e60 !important;
}

/*Backgrounds*/
.hs-bg-p1 {
  background-color: #ffa4bc !important;
}

.hs-bg-p2 {
  background-color: #303030 !important;
}

.hs-bg-p3 {
  background-color: #5296a4 !important;
}

.hs-bg-p4 {
  background-color: #ae576b !important;
}

.hs-bg-a1 {
  background-color: #f4e5e9 !important;
}

.hs-bg-a2 {
  background-color: #f5f5f5 !important;
}

.hs-bg-a3 {
  background-color: #61aabc !important;
}

.hs-bg-e1 {
  background-color: #f00 !important;
}

.hs-bg-e2 {
  background-color: #cf1c4c !important;
}

.hs-bg-e3 {
  background-color: #58b8a5 !important;
}

.hs-bg-e4 {
  background-color: #e6e6e6 !important;
}

.hs-bg-p3-s60 {
  background-color: #bad5db !important;
}

.hs-bg-p3-s90 {
  background-color: #eef5f6 !important;
}

.hs-bg-p3-t10 {
  background-color: #4a8794 !important;
}

.hs-bg-p3-t30 {
  background-color: #396973 !important;
}

.hs-bg-e3-t20 {
  background-color: #469384 !important;
}

.hs-bg-p4-s30 {
  background-color: #c68997 !important;
}

.hs-bg-p4-t10 {
  background-color: #9d4e60 !important;
}

.hs-bg-e1-s90 {
  background-color: #fcebeb !important;
}

.hs-bg-red-700 {
  background-color: #E53935 !important;
}

/*Border Color*/
.hs-bc-p1 {
  border-color: #ffa4bc !important;
}

.hs-bc-p2 {
  border-color: #303030 !important;
}

.hs-bc-p3 {
  border-color: #5296a4 !important;
}

.hs-bc-p4 {
  border-color: #ae576b !important;
}

.hs-bc-a1 {
  border-color: #f4e5e9 !important;
}

.hs-bc-a2 {
  border-color: #f5f5f5 !important;
}

.hs-bc-a3 {
  border-color: #61aabc !important;
}

.hs-bc-e1 {
  border-color: #f00 !important;
}

.hs-bc-e2 {
  border-color: #cf1c4c !important;
}

.hs-bc-e3 {
  border-color: #58b8a5 !important;
}

.hs-bc-e4 {
  border-color: #e6e6e6 !important;
}

.hs-bc-p3-s60 {
  border-color: #bad5db;
}

.hs-bc-p3-s90 {
  border-color: #eef5f6;
}

.hs-bc-p3-t10 {
  border-color: #4a8794 !important;
}

.hs-bc-p3-t30 {
  border-color: #396973 !important;
}

.hs-bc-e3-t20 {
  border-color: #469384 !important;
}

.hs-bc-p4-s30 {
  border-color: #c68997 !important;
}

.hs-bc-p4-t10 {
  border-color: #9d4e60 !important;
}

#master-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 100;
  background-color: black;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
#master-overlay.active {
  opacity: 0.7;
  left: 0;
}

#message {
  padding: 10px;
  margin: 0 0 20px 0;
  font-weight: bold;
  font-size: 16px;
}
#message.success {
  border: solid 2px #81A20F;
  color: #81A20F;
}
#message.fail {
  border: solid 2px red;
  color: red;
}

#cms-seachpage #Indexer > fieldset {
  padding: 15px;
}
#cms-seachpage #Indexer > fieldset > legend {
  width: auto;
  font-size: 18px;
  text-align: left;
}
#cms-seachpage #Indexer .loading {
  display: none;
  margin: 0 0 0 10px;
}

#menuViewTableContainer #index-menuView-container {
  display: none;
}
#menuViewTableContainer #index-btns-container {
  margin: 20px 0;
}
#menuViewTableContainer #index-btns-container > button {
  margin: 0 20px;
}
#menuViewTableContainer #smt-main-container {
  width: 100%;
}
#menuViewTableContainer #smt-main-container * {
  box-sizing: border-box;
  border: none;
}
#menuViewTableContainer #smt-main-container tr.smt-tr {
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-bottom: 0.5px solid #424242;
}
#menuViewTableContainer #smt-main-container .smt-tr.smt-th {
  background-color: #212121;
}
#menuViewTableContainer #smt-main-container .smt-tr.smt-th {
  display: table-row;
}
#menuViewTableContainer #index-allView-container #smt-main-container .smt-tr.smt-tr:hover {
  background-color: #039BE5;
}
#menuViewTableContainer #smt-main-container .smt-td.smt-th {
  background-color: unset;
  text-align: center;
}
#menuViewTableContainer #smt-main-container [data-toggle-btn] {
  cursor: pointer;
}
#menuViewTableContainer #smt-main-container .smt-tr {
  display: none;
}
#menuViewTableContainer #smt-main-container .smt-tr.smt-tr:hover {
  background-color: #039BE5;
}
#menuViewTableContainer #smt-main-container .smt-tr.smt-tr.active {
  display: table-row;
}
#menuViewTableContainer #smt-main-container .smt-tr.smt-th {
  display: table-row;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-1 {
  display: table-row;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-1 {
  background-color: #0d47a1;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-2 {
  background-color: #1e88e5;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-3 {
  background-color: #2196f3;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-4 {
  background-color: #42a5f5;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-5 {
  background-color: #64b5f6;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-6 {
  background-color: #90caf9;
}
#menuViewTableContainer #smt-main-container .smt-tr.level-7 {
  background-color: #bbdefb;
}
#menuViewTableContainer #index-allView-container #smt-main-container tr.smt-tr:not(:first-of-type) {
  background-color: #BBDEFB;
  color: #000;
  height: 30px;
}
#menuViewTableContainer #addNodes-panels {
  margin-top: 20px;
  position: relative;
  display: none;
}
#menuViewTableContainer #addNodes-panels-close, #menuViewTableContainer #editor-addParentNode-close {
  color: darkred;
  position: absolute;
  top: 10px;
  right: 10px;
}
#menuViewTableContainer #generate-categories-btns-container {
  margin: 20px 0;
}
#menuViewTableContainer #generate-categories-table input {
  width: 90%;
}
#menuViewTableContainer a.button.sm-button {
  border-radius: unset;
  margin: 0 10px;
  border: unset;
  padding: 7px 15px;
}
#menuViewTableContainer .button.sm-button.sm-button-blue {
  background: #039BE5;
}
#menuViewTableContainer .button.sm-button.sm-button-cyan {
  background: #00ACC1;
}
#menuViewTableContainer a.genCats-add-btn {
  color: #3949AB;
}
#menuViewTableContainer a#genCats-addAll-btn {
  color: #1A237E;
  font-weight: bold;
}
#menuViewTableContainer a#addNewChild-add-btn {
  background: #3949AB;
  border-radius: unset;
  margin-top: 20px;
  width: 400px;
  padding: 6px 30px;
  border: none;
}
#menuViewTableContainer a#addNew-Save-Btn, #menuViewTableContainer a#edit-Save-Btn, #menuViewTableContainer a#edit-Delete-Btn {
  background: #43A047;
  border-radius: unset;
  border: none;
  padding: 6px 25px;
  margin-right: 25px;
}
#menuViewTableContainer a#edit-Delete-Btn {
  background: #E53935;
}
#menuViewTableContainer a.smt-td-a-edit {
  color: #43A047;
}
#menuViewTableContainer a.smt-td-a-delete {
  color: #E53935;
}
#menuViewTableContainer a.menuView-a-edit {
  color: #D1C4E9;
}
#menuViewTableContainer .editor-label-desc {
  display: block;
  color: #43A047;
  font-size: 11px;
}
#menuViewTableContainer a.childToAdd-a-remove {
  color: #EF5350;
  cursor: pointer;
}

#right-drawer-container {
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100vh;
  background-color: #FAFAFA;
  z-index: 200;
  padding: 130px 30px 100px;
  overflow: hidden;
  -webkit-transition: right 0.3s linear;
  -moz-transition: right 0.3s linear;
  -ms-transition: right 0.3s linear;
  -o-transition: right 0.3s linear;
  transition: right 0.3s linear;
}
#right-drawer-container, #right-drawer-container * {
  box-sizing: border-box;
}
#right-drawer-container.active {
  right: 0;
}
#right-drawer-container #right-drawer-header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
#right-drawer-container #right-drawer-header .right-drawer-level-back-btn {
  display: none;
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 18px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  background-color: #396973;
  cursor: pointer;
}
#right-drawer-container #right-drawer-header .right-drawer-level-back-btn:hover {
  opacity: 0.8;
}
#right-drawer-container #right-drawer-close {
  position: absolute;
  top: 60px;
  right: 10px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  z-index: 20;
}
#right-drawer-container #right-drawer-loader {
  font-size: 18px;
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 20;
  display: none;
}
#right-drawer-container .right-drawer-level {
  background-color: #FAFAFA;
}
#right-drawer-container .right-drawer-level:not([rel-level="1"]) {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 130px 30px 100px;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
  -webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  -ms-transition: left 0.3s;
  -o-transition: left 0.3s;
  transition: left 0.3s;
}
#right-drawer-container .right-drawer-level .right-drawer-level-innerwrap {
  height: 600px;
  overflow: auto;
  padding: 0 30px;
  /*background-color: $color_a1;*/
}
#right-drawer-container #rightDrawer-Content {
  font-size: 14px;
}
#right-drawer-container #rightDrawer-Content h1 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
#right-drawer-container #right-drawer-modal {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
#right-drawer-container #right-drawer-modal > div {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  -moz-flex-flow: column nowrap;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-body {
  width: 90%;
  min-height: 200px;
  background-color: white;
  position: relative;
  z-index: 12;
  position: relative;
  padding: 20px 20px 60px;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-body > div:nth-of-type(1) {
  width: 100%;
  height: 100%;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-body > div:nth-of-type(2) {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  padding-right: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-body > div:nth-of-type(2) > button {
  border: none;
  padding: 0 20px;
  border-radius: 5%;
  font-size: 14px;
  color: #fff;
  height: 25px;
  cursor: pointer;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-body > div:nth-of-type(2) > button:hover {
  opacity: 0.8;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-body > div:nth-of-type(2) > button:nth-of-type(1) {
  background-color: #303030;
  margin-right: 10px;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-body > div:nth-of-type(2) > button:nth-of-type(2) {
  display: none;
  background-color: #469384;
}
#right-drawer-container #right-drawer-modal > div #right-drawer-modal-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  opacity: 0.8;
  background-color: #303030;
}

#fm-container {
  position: relative;
}
#fm-container, #fm-container * {
  box-sizing: border-box;
}
#fm-container > div:nth-of-type(1) {
  position: absolute;
  top: -90px;
  width: 100%;
  z-index: 1;
}
#fm-container > div:nth-of-type(1) > * {
  display: inline-block;
  vertical-align: middle;
}
#fm-container > div:nth-of-type(2) {
  position: absolute;
  top: -38px;
  height: 50px;
  width: 100%;
  z-index: 1;
  background-color: #f7eef0;
  padding-left: 15px;
}
#fm-container > div:nth-of-type(2) > * {
  height: inherit;
}
#fm-container > div#fm-files-container {
  overflow-y: auto;
  margin-top: 90px;
  padding-top: 30px;
}
#fm-container > div#fm-files-container > div {
  display: block;
}
#fm-container > div#fm-files-container > div#fm-files-listview {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
#fm-container > div#fm-files-container > div#fm-files-listview > div {
  padding: 10px;
}
#fm-container > div#fm-files-container > div#fm-files-listview > div:nth-of-type(1) {
  -webkit-flex-basis: 80%;
  -moz-flex-basis: 80%;
  -ms-flex-basis: 80%;
  flex-basis: 80%;
}
#fm-container > div#fm-files-container > div#fm-files-listview > div:nth-of-type(1) #fm-files-table tr {
  cursor: pointer;
}
#fm-container > div#fm-files-container > div#fm-files-listview > div:nth-of-type(1) #fm-files-table tr.active {
  background-color: #c68997;
  color: #fff;
}
#fm-container > div#fm-files-container > div#fm-files-listview > div:nth-of-type(1) #fm-files-table tr:hover {
  background-color: #bad5db;
}
#fm-container > div#fm-files-container > div#fm-files-listview > div:nth-of-type(2) {
  -webkit-flex-basis: 20%;
  -moz-flex-basis: 20%;
  -ms-flex-basis: 20%;
  flex-basis: 20%;
}
#fm-container > div#fm-files-container > div#fm-files-listview > div:nth-of-type(2) > img {
  width: 100%;
}
#fm-container > div#fm-files-container > div#fm-files-imgview > div.active {
  border: 3px solid #4a8794;
  background-color: #eef5f6;
}
#fm-container.dragAndDropActive {
  background-color: #61aabc;
}
#fm-container.dragAndDropActive .fm-breadcrumb-item, #fm-container.dragAndDropActive .fm-item-container {
  display: none;
}
#fm-container #fm-breadcrumb-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 50px;
}
#fm-container #fm-breadcrumb-container .fm-breadcrumb-item {
  font-size: 18px;
  color: #61aabc;
  font-weight: 700;
  cursor: pointer;
  height: inherit;
  line-height: 50px;
}
#fm-container #fm-breadcrumb-container .fm-breadcrumb-item:hover {
  opacity: 0.8;
}
#fm-container #fm-breadcrumb-container .fm-breadcrumb-item:last-of-type {
  color: #303030;
  pointer-events: none;
}
#fm-container .fm-item-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
#fm-container .fm-item-container .fm-item-block {
  width: 90px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}
#fm-container .fm-item-container .fm-item-block.img-block {
  width: 150px;
}
#fm-container .fm-item-container .fm-item-block.img-block.active {
  background-color: #ffa4bc;
}
#fm-container .fm-item-container .fm-item-block:hover {
  opacity: 0.9;
}
#fm-container .fm-item-container .fm-item-block > div {
  text-align: center;
}
#fm-container .fm-item-container .fm-item-block > div:nth-of-type(1) > i {
  font-size: 60px;
  color: #61aabc;
}
#fm-container .fm-item-container .fm-item-block > div:nth-of-type(1) > img {
  width: 100%;
  height: auto;
}
#fm-container .fm-item-container .fm-item-block > div:nth-of-type(2) > span {
  word-break: break-all;
}

#fm-dropzone {
  width: 300px;
  height: 300px;
  background-color: #61aabc;
  color: #fff;
  line-height: 300px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
#fm-dropzone.dropzoneActive {
  background-color: #ae576b;
}

.selectify {
  position: relative;
  min-height: 40px;
}
.selectify, .selectify * {
  box-sizing: border-box;
  font-size: 0;
}
.selectify input[type=checkbox] {
  width: 100%;
  height: 30px;
  margin: 5px 0;
}
.selectify .selectifyHeader {
  height: 40px;
  border: 1px solid black;
  display: flex;
  flex-flow: row nowrap;
}
.selectify .selectifyHeader > div {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.selectify .selectifyHeader > div:nth-of-type(1) {
  flex-grow: 1;
  overflow: auto;
}
.selectify .selectifyHeader > div:nth-of-type(1) span {
  font-size: 14px;
}
.selectify .selectifyHeader > div:nth-of-type(2) {
  flex-basis: 40px;
  min-width: 40px;
  background-color: cornflowerblue;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.selectify .selectifyBody {
  position: absolute;
  display: none;
  width: 100%;
  max-height: 300px;
  background-color: white;
  overflow: auto;
  border: 1px lightgray solid;
  z-index: 1;
}
.selectify .selectifyBody.active {
  display: block;
}
.selectify .selectifyBody > div:nth-of-type(1) {
  display: flex;
  flex-flow: row nowrap;
  background: #b1bace;
}
.selectify .selectifyBody > div:nth-of-type(1) .searchSection {
  flex-basis: 70%;
  padding: 5px;
}
.selectify .selectifyBody > div:nth-of-type(1) .searchSection input {
  width: 100%;
  height: 100%;
  font-size: 12px;
  border: 1px solid lightgray;
  border-radius: 3px;
}
.selectify .selectifyBody > div:nth-of-type(1) > div:nth-of-type(2) {
  flex-basis: 30%;
  min-width: 40px;
  text-align: center;
}
.selectify .selectifyBody > div:nth-of-type(1) > div:nth-of-type(2) > div {
  font-size: 12px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.selectify .selectifyBody .selectifyItem {
  height: 40px;
  border: 0.5px solid lightgray;
  border-right: unset;
  border-left: unset;
}
.selectify .selectifyBody .selectifyItem.active {
  background-color: #4285f4;
  color: white;
}
.selectify .selectifyBody .selectifyItem > div {
  display: inline-block;
  vertical-align: middle;
}
.selectify .selectifyBody .selectifyItem > div:nth-of-type(1) {
  width: 30%;
}
.selectify .selectifyBody .selectifyItem > div:nth-of-type(2) {
  width: 70%;
  font-size: 14px;
}

#dashboard-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#dashboard-container, #dashboard-container > * {
  box-sizing: border-box;
}
#dashboard-container #dashboard-content {
  width: 100%;
  height: 100%;
  padding-top: 80px;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
#dashboard-container #dashboard-content #dashboard-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
#dashboard-container #dashboard-content #dashboard-header > h2 {
  margin-top: 12px;
}
#dashboard-container #dashboard-content > div {
  padding: 0 10px;
  overflow: scroll;
  -webkit-flex-basis: 100%;
  -moz-flex-basis: 100%;
  -ms-flex-basis: 100%;
  flex-basis: 100%;
}
#dashboard-container #dashboard-content > div::-webkit-scrollbar {
  width: 2px;
}
#dashboard-container #dashboard-content > div::-webkit-scrollbar-thumb {
  background: #888;
}
#dashboard-container #dashboard-content > div:nth-of-type(2) > #dashboard-block-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
#dashboard-container #dashboard-content > div:nth-of-type(2) > #dashboard-block-container > .dashboard-block {
  flex-basis: 49%;
  margin-bottom: 25px;
}
#dashboard-container #dashboard-content > div:nth-of-type(2) > #dashboard-block-container > .dashboard-block .dashboard-block-settings {
  position: absolute;
  top: 5px;
  right: 5px;
}
#dashboard-container #dashboard-content > div:nth-of-type(3) {
  -webkit-flex-basis: 750px;
  -moz-flex-basis: 750px;
  -ms-flex-basis: 750px;
  flex-basis: 750px;
  -webkit-flex-grow: 0;
  -moz-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
}
#dashboard-container #dashboard-content > div:nth-of-type(3) > .card {
  margin-bottom: 25px;
}