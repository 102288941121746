﻿
@mixin nth-children($points...) {
    @each $point in $points {
        &:nth-of-type(#{$point}) {
            @content;
        }
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}


