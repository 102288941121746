﻿
@import 'shared/colors.scss';
@import 'mixins/general.scss';
@import 'mixins/flexbox.scss';

#master-overlay {
    //display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 100;
    background-color: black;
    opacity: 0;
    @include transition(opacity .5s);

    &.active {
        //display:block;
        opacity: 0.7;
        left: 0;
    }
}

#message {
    padding: 10px;
    margin: 0 0 20px 0;
    font-weight: bold;
    font-size: 16px;

    &.success {
        border: solid 2px #81A20F;
        color: #81A20F;
    }

    &.fail {
        border: solid 2px red;
        color: red;
    }
}


#cms-seachpage {

    #message {
    }

    #Indexer {
        > fieldset {
            padding: 15px;
            //background-color:lightgray;
            > legend {
                width: auto;
                font-size: 18px;
                text-align: left;
            }
        }

        .loading {
            display: none;
            margin: 0 0 0 10px;
        }
    }
}

#menuViewTableContainer{

    #index-menuView-container{display:none}
    #index-btns-container{margin: 20px 0;}
    #index-btns-container > button{margin:0 20px;}

    
    #smt-main-container {width:100%;}
    #smt-main-container * {box-sizing:border-box; border:none; }
    #smt-main-container tr.smt-tr{line-height:30px; text-align:center; color:#fff; border-bottom:0.5px solid #424242; }
    #smt-main-container .smt-tr.smt-th{background-color:#212121;}
    #smt-main-container .smt-tr.smt-th{display:table-row;}
    #index-allView-container #smt-main-container .smt-tr.smt-tr:hover{background-color:#039BE5;}
    #smt-main-container .smt-td.smt-th{background-color:unset; text-align:center;}
    #smt-main-container [data-toggle-btn] {cursor:pointer;}

    #smt-main-container .smt-tr{display:none;}
    #smt-main-container .smt-tr.smt-tr:hover{background-color:#039BE5;}
    #smt-main-container .smt-tr.smt-tr.active{display:table-row;}
    #smt-main-container .smt-tr.smt-th{display:table-row;}
    #smt-main-container .smt-tr.level-1{display:table-row;}

    #smt-main-container .smt-tr.level-1{background-color:#0d47a1;}
    #smt-main-container .smt-tr.level-2{background-color:#1e88e5;}
    #smt-main-container .smt-tr.level-3{background-color:#2196f3;}
    #smt-main-container .smt-tr.level-4{background-color:#42a5f5;}
    #smt-main-container .smt-tr.level-5{background-color:#64b5f6;}
    #smt-main-container .smt-tr.level-6{background-color:#90caf9}
    #smt-main-container .smt-tr.level-7{background-color:#bbdefb}

    #index-allView-container  #smt-main-container tr.smt-tr:not(:first-of-type) { background-color:#BBDEFB; color:#000; height: 30px;}

    #addNodes-panels{margin-top:20px; position:relative; display:none;}
#addNodes-panels-close,#editor-addParentNode-close { color:darkred; position:absolute; top:10px; right:10px;}
#generate-categories-btns-container{margin: 20px 0;}
#generate-categories-table input {width:90%;}
a.button.sm-button { border-radius:unset; margin: 0 10px; border:unset; padding: 7px 15px; }
.button.sm-button.sm-button-blue{background:#039BE5; }
.button.sm-button.sm-button-cyan {background:#00ACC1; }
a.genCats-add-btn{color:#3949AB}
a#genCats-addAll-btn { color: #1A237E; font-weight:bold;}
a#addNewChild-add-btn{ background:#3949AB; border-radius: unset; margin-top: 20px; width: 400px; padding:6px 30px; border:none;}
a#addNew-Save-Btn, a#edit-Save-Btn,a#edit-Delete-Btn{background:#43A047; border-radius:unset; border:none; padding: 6px 25px; margin-right:25px;  }
a#edit-Delete-Btn { background:#E53935; }
a.smt-td-a-edit{color:#43A047;}
a.smt-td-a-delete{color:#E53935;}
a.menuView-a-edit { color: #D1C4E9;}

.editor-label-desc{display:block; color:#43A047; font-size: 11px;}

a.childToAdd-a-remove{color:#EF5350; cursor:pointer;}
}

#right-drawer-container {

    $wrapPadding: 130px 30px 100px;

    position: fixed;
    top: 0;
    right: -100%;
    width: 300px;
    height: 100vh;
    //background-color: white;
    background-color: $color_gray_50;
    z-index: 200;
    padding: $wrapPadding;
    overflow: hidden;

    @include transition(right 0.3s linear);

    &, & * {
        box-sizing: border-box;
        //font-size:0;
    }

    &.active {
        right: 0;
    }

    #right-drawer-header {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        .right-drawer-level-back-btn {
            display: none;
            width: 100%;
            height: 50px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            font-size: 18px;
            line-height: 50px;
            color: #fff; //$color_p2;
            text-align: center;
            background-color: $color_p3_t30; //$color_e3;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    #right-drawer-close {
        position: absolute;
        top: 60px;
        right: 10px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        z-index: 20;
    }

    #right-drawer-loader {
        font-size: 18px;
        position: absolute;
        top: 60px;
        left: 10px;
        z-index: 20;
        display: none;
    }

    .right-drawer-level {
        background-color: $color_gray_50;

        &:not([rel-level="1"]) {
            position: absolute;
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            //background-color: white;
            z-index: 1;
            padding: $wrapPadding;
            padding-right: 0;
            padding-left: 0;
            overflow: hidden;

            @include transition(left .3s);
        }

        .right-drawer-level-innerwrap {
            height: 600px;
            overflow: auto;
            padding: 0 30px;
            /*background-color: $color_a1;*/
        }
    }

    #rightDrawer-Content {
        font-size: 14px;

        h1 {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    #right-drawer-modal {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        > div {
            height: 100%;
            width: 100%;

            @include flexbox();
            @include flex-flow(column nowrap);
            @include justify-content(center);
            @include align-items(center);

            #right-drawer-modal-body {
                width: 90%;
                min-height: 200px;
                background-color: white;
                position: relative;
                z-index: 12;
                position: relative;
                padding: 20px 20px 60px;

                > div {

                    &:nth-of-type(1) {
                        width: 100%;
                        height: 100%;
                    }

                    &:nth-of-type(2) {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 60px;
                        padding-right: 20px;

                        @include flexbox();
                        @include flex-flow(row nowrap);
                        @include justify-content(flex-end);
                        @include align-items(center);

                        > button {
                            border: none;
                            padding: 0 20px;
                            border-radius: 5%;
                            font-size: 14px;
                            color: #fff;
                            height: 25px;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }


                            &:nth-of-type(1) {
                                background-color: $color_p2;
                                margin-right: 10px;
                            }

                            &:nth-of-type(2) {
                                display: none;
                                background-color: $color_e3_t20; //$color_p1;
                            }
                        }
                    }
                }
            }

            #right-drawer-modal-overlay {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 11;
                opacity: 0.8;
                background-color: $color_p2;
            }
        }
    }
}

#fm-container {
    position: relative;

    &, & * {
        box-sizing: border-box;
    }

    > div {
        &:nth-of-type(1) {
            position: absolute;
            top: -90px;
            //height: 45px;
            width: 100%;
            z-index: 1;

            >*{
                display:inline-block;
                vertical-align:middle;
            }
        }

        &:nth-of-type(2) {
            position: absolute;
            top: -38px;
            height: 50px;
            width: 100%;
            z-index: 1;
            background-color: $color_p4_s90;
            padding-left: 15px;

            >*{
                height:inherit;


            }
        }

        &#fm-files-container {
            overflow-y: auto;            
            margin-top:90px;
            padding-top:30px;

            > div {
                display: block;

                &#fm-folders-container {
                    //padding-top: 90px;
                }

                &#fm-files-listview {
                    @include flexbox();
                    @include flex-flow(row nowrap);

                    > div {
                        padding: 10px;

                        &:nth-of-type(1) {
                            @include flex-basis(80%);

                            #fm-files-table {

                                tr {
                                    cursor: pointer;

                                    &.active {
                                        background-color: $color_p4_s30;
                                        color: #fff;
                                    }

                                    &:hover {
                                        background-color: $color_p3_s60;
                                    }
                                }
                            }
                        }

                        &:nth-of-type(2) {
                            @include flex-basis(20%);

                            > img {
                                width: 100%;
                            }
                        }
                    }
                }

                &#fm-files-imgview {
                    > div {
                        &.active {
                            border: 3px solid $color_p3_t10;
                            background-color: $color_p3_s90;
                        }
                    }
                }
            }
        }
    }

    &.dragAndDropActive {
        background-color: $color_a3;

        .fm-breadcrumb-item, .fm-item-container {
            display: none;
        }
    }

    #fm-breadcrumb-container {
        @include flexbox();

        height:50px;

        .fm-breadcrumb-item {
            font-size: 18px;
            color: $color_a3;
            font-weight: 700;
            cursor: pointer;
            height:inherit;
            line-height:50px;

            &:hover {
                opacity: .8;
            }

            &:last-of-type {
                color: $color_p2;
                pointer-events: none;
            }
        }
    }

    .fm-item-container {
        @include flexbox();
        @include flex-flow(row wrap);

        .fm-item-block {
            width: 90px;
            padding: 10px;
            margin: 5px;
            cursor: pointer;

            &.img-block {
                width: 150px;

                &.active {
                    background-color: $color_p1;
                }
            }

            &:hover {
                opacity: .9;
            }

            > div {
                text-align: center;

                &:nth-of-type(1) {

                    > i {
                        font-size: 60px;
                        color: $color_a3;
                    }

                    > img {
                        width: 100%;
                        height: auto;
                    }
                }

                &:nth-of-type(2) {
                    > span {
                        word-break: break-all;
                    }
                }
            }
        }
    }
}

#fm-dropzone {
    width: 300px;
    height: 300px;
    background-color: $color_a3;
    color:#fff;
    line-height:300px;
    text-align:center;
    font-size:18px;
    font-weight:700;

    &.dropzoneActive {
        background-color: $color_p4;
    }
}