﻿
$primaryColor: #4285f4 ;

.selectify {

    &, & * {
        box-sizing: border-box;
        font-size: 0;
    }

    & input[type="checkbox"] {
        width: 100%;
        height: 30px;
        margin: 5px 0;
    }

    position: relative;
    min-height: 40px;

    .selectifyHeader {
        height: 40px;
        border: 1px solid black;
        display: flex;
        flex-flow: row nowrap;

        > div {
            display: inline-block;
            vertical-align: middle;
            height: 100%;

            &:nth-of-type(1) {
                flex-grow: 1;
                overflow: auto;

                span {
                    font-size: 14px;
                }
            }

            &:nth-of-type(2) {
                flex-basis: 40px;
                min-width: 40px;
                background-color: cornflowerblue;
                font-size: 20px;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
            }
        }
    }



    .selectifyBody {
        position: absolute;
        display: none;
        width: 100%;
        max-height: 300px;
        background-color: white;
        overflow: auto;
        border:1px lightgray solid;
        z-index:1;

        &.active {
            display: block;
        }

        & > div:nth-of-type(1) {
            display: flex;
            flex-flow: row nowrap;
            background:#b1bace;

            .searchSection {
                flex-basis: 70%;
                padding: 5px;

                input {
                    width: 100%;
                    height: 100%;
                    font-size: 12px;
                    border: 1px solid lightgray;
                    border-radius: 3px;
                }
            }

            > div:nth-of-type(2) {
                flex-basis: 30%;
                min-width: 40px;
                text-align: center;

                > div {
                    font-size: 12px;
                    color: blue;
                    text-decoration: underline;
                    cursor:pointer;
                }
            }
        }



        .selectifyItem {
            height: 40px;
            border: 0.5px solid lightgray;
            border-right:unset;
            border-left:unset;

            &.active {
                background-color: #4285f4;
                color: white;
            }

            > div {
                display: inline-block;
                vertical-align: middle;

                &:nth-of-type(1) {
                    width: 30%;
                }

                &:nth-of-type(2) {
                    width: 70%;
                    font-size: 14px;
                }
            }
        }
    }
}
