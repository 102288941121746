﻿@import 'mixins/flexbox.scss';

#dashboard-container {

    &, & > * {
        box-sizing: border-box;
        //font-size:0;
    }

    width: 100%;
    height: 100vh;
    overflow: hidden;



    #dashboard-content {
        width: 100%;
        height: 100%;
        padding-top: 80px;
        position:relative;

        @include flexbox();
        @include flex-flow(row nowrap);

        #dashboard-header {
            //height: 80px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            >h2{
                margin-top:12px;
            }
        }

        > div {
            padding: 0 10px;
            overflow: scroll;

            &::-webkit-scrollbar {
                width: 2px;
                //display: none;
            }

            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            //@include flex-shrink(1);
            @include flex-basis(100%);

            &:nth-of-type(2) {
                //@include flex-grow(1);
                > #dashboard-block-container {
                    @include flexbox();
                    @include flex-flow(row wrap);
                    @include justify-content(space-between);

                    > .dashboard-block {
                        flex-basis: 49%;
                        margin-bottom: 25px;

                        &:nth-of-type(3) {
                            //flex-basis:100%;
                        }

                        .dashboard-block-settings {
                            position: absolute;
                            top: 5px;
                            right: 5px;
                        }
                    }
                }
            }

            &:nth-of-type(3) {
                @include flex-basis(750px);
                @include flex-grow(0);

                > .card {
                    margin-bottom: 25px;
                }
            }
        }
    }
}
