﻿@import 'vars.scss';

.hs-color-p1{color:$color_p1!important;}
.hs-color-p2{color:$color_p2!important;}
.hs-color-p3{color:$color_p3!important;}
.hs-color-p4{color:$color_p4!important;}
   
.hs-color-a1{color:$color_a1!important;}
.hs-color-a2{color:$color_a2!important;}
.hs-color-a3{color:$color_a3!important;}
   
.hs-color-e1{color:$color_e1!important;}
.hs-color-e2{color:$color_e2!important;}
.hs-color-e3{color:$color_e3!important;}
.hs-color-e4{color:$color_e4!important;}
   
.hs-color-p3-s60{color:$color_p3_s60}
.hs-color-p3-s90{color:$color_p3_s90}
   
.hs-color-p3-t10{color:$color_p3_t10!important;}
.hs-color-p3-t30{color:$color_p3_t30!important;}
   
.hs-color-e3-t20{color:$color_e3_t20!important;}
   
.hs-color-p4-s30{color:$color_p4_s30!important;}
.hs-color-p4-t10{color:$color_p4_t10!important;}


/*Backgrounds*/

.hs-bg-p1{background-color:$color_p1!important;}
.hs-bg-p2{background-color:$color_p2!important;}
.hs-bg-p3{background-color:$color_p3!important;}
.hs-bg-p4{background-color:$color_p4!important;}
   
.hs-bg-a1{background-color:$color_a1!important;}
.hs-bg-a2{background-color:$color_a2!important;}
.hs-bg-a3{background-color:$color_a3!important;}
   
.hs-bg-e1{background-color:$color_e1!important;}
.hs-bg-e2{background-color:$color_e2!important;}
.hs-bg-e3{background-color:$color_e3!important;}
.hs-bg-e4{background-color:$color_e4!important;}
   
.hs-bg-p3-s60{background-color:$color_p3_s60!important;}
.hs-bg-p3-s90{background-color:$color_p3_s90!important;}
   
.hs-bg-p3-t10{background-color:$color_p3_t10!important;}
.hs-bg-p3-t30{background-color:$color_p3_t30!important;}
   
.hs-bg-e3-t20{background-color:$color_e3_t20!important;}
   
.hs-bg-p4-s30{background-color:$color_p4_s30!important;}
.hs-bg-p4-t10{background-color:$color_p4_t10!important;}

.hs-bg-e1-s90 {background-color:$color_e1_s90!important;}

.hs-bg-red-700{background-color:$color_red_700!important;}

/*Border Color*/

.hs-bc-p1{border-color:$color_p1!important;}
.hs-bc-p2{border-color:$color_p2!important;}
.hs-bc-p3{border-color:$color_p3!important;}
.hs-bc-p4{border-color:$color_p4!important;}
   
.hs-bc-a1{border-color:$color_a1!important;}
.hs-bc-a2{border-color:$color_a2!important;}
.hs-bc-a3{border-color:$color_a3!important;}
   
.hs-bc-e1{border-color:$color_e1!important;}
.hs-bc-e2{border-color:$color_e2!important;}
.hs-bc-e3{border-color:$color_e3!important;}
.hs-bc-e4{border-color:$color_e4!important;}
   
.hs-bc-p3-s60{border-color:$color_p3_s60}
.hs-bc-p3-s90{border-color:$color_p3_s90}
   
.hs-bc-p3-t10{border-color:$color_p3_t10!important;}
.hs-bc-p3-t30{border-color:$color_p3_t30!important;}
   
.hs-bc-e3-t20{border-color:$color_e3_t20!important;}
   
.hs-bc-p4-s30{border-color:$color_p4_s30!important;}
.hs-bc-p4-t10{border-color:$color_p4_t10!important;}






